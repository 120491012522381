@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






































































































































































































































































































.drawer__info-header
  h5
    font-size: $font-size-m

.drawer__info-header
  .info-2
    .info__content
      label
        font-size: $font-size-s

.drawer__info-footer
  .info__content
    .info__text
      h5
        font-size: $font-size-m

    .info__label
      font-size: $font-size-s

.csat-button
  color: $color_ink_light
  display: flex
  flex-direction: row
  align-items: center
  &:hover
    cursor: pointer
